import { getDateWithZeroTime } from '@1clickfactory/common/helpers/date.helpers';
import { NotificationMessage } from '@1clickfactory/notifications';
import { showCustomNotification, showNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { identitySelectors, supportActions } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.store';
import * as fromActions from './support.actions';
import { Ticket, TicketListItem } from './support.model';
import { SupportService } from './support.service';

@Injectable()
export class SupportEffects {
  onGetSupportSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSupportSetup),
      switchMap(() =>
        forkJoin([this.supportService.getTypes(), this.supportService.getCategories(), this.supportService.getSeverities()]).pipe(
          map(([types, categories, severities]) => fromActions.getSupportSetupComplete({ types, categories, severities })),
          catchError(() => of(fromActions.getSupportSetupError())),
        ),
      ),
    ),
  );

  onGetTicketsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTickets),
      withLatestFrom(this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.Support.Tickets.ReadAll'])))),
      mergeMap(({ 1: permission }) =>
        this.supportService.getAll(permission).pipe(
          map((ticketsJson: TicketListItem[]) =>
            ticketsJson.map(ticket => ({
              ...ticket,
              creationDate: getDateWithZeroTime(ticket.creationDate),
              lastUpdateDate: getDateWithZeroTime(ticket.lastUpdateDate),
            })),
          ),
          map((tickets: TicketListItem[]) => fromActions.getTicketsComplete({ tickets })),
          catchError(({ error }) => of(fromActions.getTicketsError({ error }))),
        ),
      ),
    ),
  );

  onGetTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTicket),
      mergeMap(({ id }) =>
        this.supportService.get(id).pipe(
          map((ticket: Ticket) => ({
            ...ticket,
            creationDate: getDateWithZeroTime(ticket.creationDate),
            lastUpdateDate: getDateWithZeroTime(ticket.lastUpdateDate),
          })),
          map((ticket: Ticket) => fromActions.getTicketComplete({ ticket })),
          catchError(({ error }) => of(fromActions.getTicketError({ error }))),
        ),
      ),
    ),
  );

  onCreateNewTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createNewTicket),
      switchMap(({ newTicket }) =>
        this.supportService.create(newTicket).pipe(
          map(createdTicket => ({
            ...createdTicket,
            lastUpdateDate: getDateWithZeroTime(createdTicket.lastUpdateDate),
          })),
          map((ticket: TicketListItem) => fromActions.createNewTicketComplete({ ticket })),
          catchError(({ error }) => of(fromActions.createNewTicketError({ error }))),
        ),
      ),
    ),
  );

  onGetNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNote),
      mergeMap(({ id }) =>
        this.supportService.getNote(id).pipe(
          map(note => fromActions.getNoteComplete({ note })),
          catchError(error => of(fromActions.getNoteError({ error }))),
        ),
      ),
    ),
  );

  onAddNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNote),
      mergeMap(({ id, newNote }) =>
        this.supportService.addNote(newNote, id).pipe(
          map(newNote => fromActions.addNoteComplete({ id, newNote })),
          catchError(({ error }) => of(fromActions.addNoteError({ error }))),
        ),
      ),
    ),
  );

  onCreateOrUpdateTicketComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.createNewTicketComplete, fromActions.addNoteComplete),
        map(() => {
          this.store.dispatch(supportActions.clearAttachments());
        }),
      ),
    { dispatch: false },
  );

  onGetAttachmentsUploadUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAttachmentsUploadUrls),
      mergeMap(({ amountOfAttachments }) =>
        this.supportService.getAttachmentUploadUrl(amountOfAttachments).pipe(
          map(attachmentsURL => fromActions.getAttachmentsUploadUrlsComplete({ attachmentsURL })),
          catchError(({ error }) => of(fromActions.getAttachmentsUploadUrlsError({ error }))),
        ),
      ),
    ),
  );

  onDownloadTicketAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadAttachment),
      mergeMap(({ attachment }) =>
        this.supportService.downloadAttachments(attachment).pipe(
          map(attachment => fromActions.downloadAttachmentComplete({ attachment })),
          catchError(() => of(fromActions.downloadAttachmentError())),
        ),
      ),
    ),
  );

  onDownloadTicketAttachmentComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadAttachmentComplete),
        tap(({ attachment }) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([attachment.blob], { type: attachment.blob.type }));

          downloadLink.setAttribute('download', attachment.fileName);
          downloadLink.click();
        }),
      ),
    { dispatch: false },
  );

  onDownloadTicketAttachmentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadAttachmentError),
      map(() => showNotification({ message: NotificationMessage.DownloadError })),
    ),
  );

  onError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.createNewTicketError,
        fromActions.addNoteError,
        fromActions.resolveCaseError,
        fromActions.getTicketsError,
        fromActions.getTicketError,
        fromActions.getAttachmentsUploadUrlsError,
      ),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  onGetTicketError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getTicketError, fromActions.addNoteComplete),
        tap(() => {
          this.store.dispatch(supportActions.goToSupportPage());
        }),
      ),
    { dispatch: false },
  );

  onGoToSupportPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSupportPage),
        switchMap(() => this.router.navigate(['/', 'support'])),
      ),
    { dispatch: false },
  );

  onGoToUpdateTicketPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToUpdateTicketPage),
        switchMap(({ id }) => this.router.navigate(['/', 'support', id])),
      ),
    { dispatch: false },
  );

  onResolveCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resolveCase),
      mergeMap(({ id }) =>
        this.supportService.resolveCase(id).pipe(
          map(() => fromActions.getTicket({ id })),
          catchError(({ error }) => of(fromActions.resolveCaseError(error))),
        ),
      ),
    ),
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private supportService: SupportService,
  ) {}
}
