import { ICustomer } from '@appState';
import { createReducer, on } from '@ngrx/store';
import { AssessmentToggleOptions, IAssessment } from '../assessment.model';
import * as fromActions from './assessments-list.actions';

export interface State {
  assessments: IAssessment[];
  isLoading: boolean;
  assessmentListIsLoading: boolean;
  customerCreationIsLoading: boolean;
  createdCustomer: ICustomer | null;
  selectedAssessment: IAssessment | null;
  assessmentSaveCompleted: boolean;
  toggle: AssessmentToggleOptions;
}

const initialState: State = {
  assessments: [],
  isLoading: false,
  assessmentListIsLoading: false,
  customerCreationIsLoading: false,
  createdCustomer: null,
  selectedAssessment: null,
  assessmentSaveCompleted: false,
  toggle: AssessmentToggleOptions.Open,
};

export const assessmentsListReducer = createReducer(
  initialState,
  on(fromActions.getAssessmentsList, (state, { toggle }) => ({
    ...state,
    toggle: toggle ?? state.toggle,
    assessmentListIsLoading: true,
  })),
  on(fromActions.getAssessmentsListComplete, (state, { assessments }) => ({
    ...state,
    assessmentListIsLoading: false,
    assessments: assessments,
  })),
  on(fromActions.getAssessmentsListError, state => ({ ...state, assessmentListIsLoading: false })),
  on(fromActions.addAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.addAssessmentComplete, (state, { assessment }) => ({
    ...state,
    selectedAssessment: assessment,
    createdCustomer: null,
    isLoading: false,
  })),
  on(fromActions.addAssessmentError, state => ({ ...state, createdCustomer: null, isLoading: false })),
  on(fromActions.selectAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.selectAssessmentComplete, (state, { assessment }) => ({
    ...state,
    selectedAssessment: assessment,
    assessmentSaveCompleted: false,
    isLoading: false,
  })),
  on(fromActions.selectAssessmentError, state => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.updateAssessment, state => ({ ...state, isLoading: true, assessmentSaveCompleted: false })),
  on(fromActions.updateAssessmentComplete, (state, { assessment, isDraft }) => ({
    ...state,
    isLoading: false,
    selectedAssessment: assessment,
    assessmentSaveCompleted: !isDraft,
  })),
  on(fromActions.updateAssessmentError, state => ({ ...state, isLoading: false, assessmentSaveCompleted: false })),
  on(fromActions.submitAssessment, state => ({ ...state, isLoading: true, assessmentSaveCompleted: false })),
  on(fromActions.submitAssessmentComplete, (state, { assessment }) => ({
    ...state,
    isLoading: false,
    selectedAssessment: assessment,
    assessmentSaveCompleted: false,
  })),
  on(fromActions.submitAssessmentError, state => ({ ...state, isLoading: false, assessmentSaveCompleted: false })),
  on(fromActions.cancelAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.cancelAssessmentComplete, (state, { assessment }) => ({ ...state, isLoading: false, selectedAssessment: assessment })),
  on(fromActions.cancelAssessmentError, state => ({ ...state, isLoading: false })),
  on(fromActions.deleteAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.deleteAssessmentComplete, state => ({ ...state, isLoading: false, selectedAssessment: null })),
  on(fromActions.deleteAssessmentError, state => ({ ...state, isLoading: false })),
  on(fromActions.resetAssessmentSaveCompleted, state => ({ ...state, assessmentSaveCompleted: false })),
  on(fromActions.addCustomer, state => ({ ...state, customerCreationIsLoading: true })),
  on(fromActions.addCustomerComplete, (state, { customer }) => ({ ...state, createdCustomer: customer, customerCreationIsLoading: false })),
  on(fromActions.addCustomerError, state => ({ ...state, customerCreationIsLoading: false })),
  on(fromActions.submitUpdateAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.submitUpdateAssessmentComplete, (state, { assessment }) => ({
    ...state,
    isLoading: false,
    selectedAssessment: assessment,
  })),
  on(fromActions.submitUpdateAssessmentError, state => ({ ...state, isLoading: false })),
);
