import { environment } from '@env/environment';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './user-anonymous.actions';

enum AlertMessage {
  DefaultErrorMessage = 'We could not complete your request. Please try again or contact our support if the issue persists.',
}

type AlertType = 'success' | 'info' | 'warning' | 'danger';

export interface State {
  isLoading: boolean;
  resetHumanCheckToken: boolean;
  alert: {
    show: boolean;
    type: AlertType | undefined;
    message: AlertMessage | string;
  };
}

export const initialState: State = {
  isLoading: false,
  resetHumanCheckToken: false,
  alert: {
    show: false,
    type: undefined,
    message: '',
  },
};

export const userAnonymousReducer = createReducer(
  initialState,
  on(fromActions.submitApplicationForm, state => ({ ...state, isLoading: true, resetHumanCheckToken: false })),
  on(fromActions.submitApplicationFormComplete, state => ({ ...state, isLoading: false, resetHumanCheckToken: false })),
  on(fromActions.submitApplicationFormError, state => ({ ...state, isLoading: false, resetHumanCheckToken: true })),

  on(fromActions.submitForgotPasswordForm, state => ({
    ...state,
    isLoading: true,
    resetHumanCheckToken: false,
  })),
  on(fromActions.submitForgotPasswordFormComplete, state => ({
    ...state,
    isLoading: false,
    resetHumanCheckToken: true,
    alert: {
      show: true,
      type: 'info' as const,
      message:
        '<span>If the provided email address is registered, you will receive the link for password reset. <strong>Link will be valid for one hour.</strong></span>',
    },
  })),
  on(fromActions.submitForgotPasswordFormError, state => ({
    ...state,
    isLoading: false,
    resetHumanCheckToken: true,
    alert: {
      show: true,
      type: 'danger' as const,
      message: AlertMessage.DefaultErrorMessage,
    },
  })),

  on(fromActions.submitResetPasswordForm, state => ({
    ...state,
    isLoading: true,
    resetHumanCheckToken: false,
  })),
  on(fromActions.submitResetPasswordFormComplete, state => ({
    ...state,
    isLoading: false,
    resetHumanCheckToken: true,
    alert: {
      show: true,
      type: 'success' as const,
      message: `<span>Password has been changed. Click <a href="${environment.baseUrl}" style="cursor: pointer"><strong>here</strong></a> to sign in.</span>`,
    },
  })),
  on(fromActions.submitResetPasswordFormError, state => ({
    ...state,
    isLoading: false,
    resetHumanCheckToken: true,
    alert: {
      show: true,
      type: 'danger' as const,
      message: AlertMessage.DefaultErrorMessage,
    },
  })),

  on(fromActions.tokenValidationError, state => ({
    ...state,
    isLoading: false,
    resetHumanCheckToken: true,
    alert: {
      show: true,
      type: 'danger' as const,
      message: `Provided link for password reset has been expired or not valid. Please try again to send a new request <a href="${environment.baseUrl}/anonymous/forgot-password" style="cursor: pointer"><strong>here</strong></a>.</span>`,
    },
  })),
);
