import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AssessmentCreateRequest,
  AssessmentResponse,
  AssessmentStatusFilter,
  AssessmentSubmitUpdateRequest,
  AssessmentUpdateRequest,
  DownloadURL,
} from './assessment.model';

@Injectable()
export class AssessmentsService {
  private readonly path = (url = ''): string => `assessments/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getAll(status?: AssessmentStatusFilter): Observable<AssessmentResponse[]> {
    return this.highlanderService.get<AssessmentResponse[]>(this.path(), {
      params: {
        ...(status && { status: this.convertToMultiValuedParam<AssessmentStatusFilter>(status) }),
      },
    });
  }

  get(id: string): Observable<AssessmentResponse> {
    return this.highlanderService.get<AssessmentResponse>(this.path(`${id}`));
  }

  create(model: AssessmentCreateRequest): Observable<AssessmentResponse> {
    return this.highlanderService.post<AssessmentCreateRequest, AssessmentResponse>(this.path(), model);
  }

  update(id: string, model: AssessmentUpdateRequest): Observable<AssessmentResponse> {
    return this.highlanderService.put<AssessmentUpdateRequest, AssessmentResponse>(this.path(`${id}`), model);
  }

  submit(id: string): Observable<AssessmentResponse> {
    return this.highlanderService.post<null, AssessmentResponse>(this.path(`${id}/submit`));
  }

  cancel(id: string): Observable<AssessmentResponse> {
    return this.highlanderService.post<null, AssessmentResponse>(this.path(`${id}/cancel`));
  }

  delete(id: string): Observable<void> {
    return this.highlanderService.delete(this.path(`${id}`));
  }

  getDownloadUrl(id: string, fileId: string): Observable<DownloadURL> {
    return this.highlanderService.get<DownloadURL>(this.path(`${id}/files/${fileId}/download-url`));
  }

  submitUpdate(id: string, model: AssessmentSubmitUpdateRequest): Observable<AssessmentResponse> {
    return this.highlanderService.post<AssessmentSubmitUpdateRequest, AssessmentResponse>(this.path(`${id}/submit-update`), model);
  }

  private convertToMultiValuedParam<T extends string[]>(values: T): string {
    return values.join('|');
  }
}
