import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { CustomerCreateRequest, ICustomer } from '@appState';
import { createAction, props } from '@ngrx/store';
import {
  AssessmentCreateRequest,
  AssessmentToggleOptions,
  AssessmentUpdateRequest,
  IAssessment,
  AssessmentSubmitUpdateRequest,
} from '../assessment.model';

const NAMESPACE = '[Assessments List]';

export const getAssessmentsList = createAction(`${NAMESPACE} GetAssessmentsList`, props<{ toggle: AssessmentToggleOptions }>());
export const getAssessmentsListComplete = createAction(`${NAMESPACE} GetAssessmentsListComplete`, props<{ assessments: IAssessment[] }>());
export const getAssessmentsListError = createAction(
  `${NAMESPACE} GetAssessmentsListError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const addAssessment = createAction(`${NAMESPACE} AddAssessment`, props<{ assessment: AssessmentCreateRequest }>());
export const addAssessmentComplete = createAction(
  `${NAMESPACE} AddAssessmentComplete`,
  props<{ assessment: IAssessment; redirectUrl: string }>(),
);
export const addAssessmentError = createAction(`${NAMESPACE} AddAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const selectAssessment = createAction(`${NAMESPACE} SelectAssessment`, props<{ id: string }>());
export const selectAssessmentComplete = createAction(`${NAMESPACE} SelectAssessmentComplete`, props<{ assessment: IAssessment }>());
export const selectAssessmentError = createAction(`${NAMESPACE} SelectAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateAssessment = createAction(
  `${NAMESPACE} UpdateAssessment`,
  props<{ id: string; assessment: AssessmentUpdateRequest; isDraft: boolean }>(),
);
export const updateAssessmentComplete = createAction(
  `${NAMESPACE} UpdateAssessmentComplete`,
  props<{ assessment: IAssessment; isDraft: boolean }>(),
);
export const updateAssessmentError = createAction(`${NAMESPACE} UpdateAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const submitAssessment = createAction(`${NAMESPACE} SubmitAssessment`);
export const submitAssessmentComplete = createAction(
  `${NAMESPACE} SubmitAssessmentComplete`,
  props<{ assessment: IAssessment; redirectUrl: string }>(),
);
export const submitAssessmentError = createAction(`${NAMESPACE} SubmitAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const cancelAssessment = createAction(`${NAMESPACE} CancelAssessment`);
export const cancelAssessmentComplete = createAction(
  `${NAMESPACE} CancelAssessmentComplete`,
  props<{ assessment: IAssessment; redirectUrl: string }>(),
);
export const cancelAssessmentError = createAction(`${NAMESPACE} CancelAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const deleteAssessment = createAction(`${NAMESPACE} DeleteAssessment`);
export const deleteAssessmentComplete = createAction(`${NAMESPACE} DeleteAssessmentComplete`, props<{ redirectUrl: string }>());
export const deleteAssessmentError = createAction(`${NAMESPACE} DeleteAssessmentError`, props<{ error: HighlanderCoreErrorResponse }>());

export const resetAssessmentSaveCompleted = createAction(`${NAMESPACE} ResetAssessmentSaveCompleted`);

export const addCustomer = createAction(`${NAMESPACE} AddCustomer`, props<{ customer: CustomerCreateRequest }>());
export const addCustomerComplete = createAction(`${NAMESPACE} AddCustomerComplete`, props<{ customer: ICustomer }>());
export const addCustomerError = createAction(`${NAMESPACE} AddCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const downloadFile = createAction(`${NAMESPACE} DownloadFile`, props<{ id: string; fileId: string }>());
export const downloadFileComplete = createAction(`${NAMESPACE} DownloadFileComplete`, props<{ url: string }>());
export const downloadFileError = createAction(`${NAMESPACE} DownloadFileError`, props<{ error: HighlanderCoreErrorResponse }>());

export const submitUpdateAssessment = createAction(
  `${NAMESPACE} SubmitUpdateAssessment`,
  props<{ id: string; submitUpdate: AssessmentSubmitUpdateRequest }>(),
);

export const submitUpdateAssessmentComplete = createAction(
  `${NAMESPACE} SubmitUpdateAssessmentComplete`,
  props<{ assessment: IAssessment; redirectUrl: string }>(),
);

export const submitUpdateAssessmentError = createAction(
  `${NAMESPACE} SubmitUpdateAssessmentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);
