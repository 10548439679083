import { downloadBlobUrlFileToBrowser } from '@1clickfactory/common/helpers/file.helpers';
import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AssessmentToggleOptions, ICustomer, assessmentsListActions, assessmentsListSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { CustomersService } from 'src/app/app-state/customers/customers.service';
import { AssessmentsService } from '../assessments.service';
import * as fromActions from './assessments-list.actions';
import * as fromSelectors from './assessments-list.selectors';

@Injectable()
export class AssessmentsListEffects {
  onGetAssessmentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAssessmentsList),
      switchMap(({ toggle }) =>
        (toggle === AssessmentToggleOptions.Open
          ? this.assessmentsService.getAll(['Saved', 'Submitted', 'Analyzing', 'OnHold', 'Completed'])
          : this.assessmentsService.getAll()
        ).pipe(
          map(assessments =>
            fromActions.getAssessmentsListComplete({ assessments: assessments.map(v => ({ actions: v.actions, ...v.assessment })) }),
          ),
          catchError(({ error }) => of(fromActions.getAssessmentsListError({ error }))),
        ),
      ),
    ),
  );

  onAddAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAssessment),
      switchMap(({ assessment }) =>
        this.assessmentsService.create({ ...assessment }).pipe(
          map(assessment => {
            return fromActions.addAssessmentComplete({
              assessment: { actions: assessment.actions, ...assessment.assessment },
              redirectUrl: `mm-program/${assessment.assessment.id}/details`,
            });
          }),
          catchError(({ error }) => of(fromActions.addAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onAddAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAssessmentComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      switchMap(() =>
        this.store.pipe(
          select(fromSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAssessmentsList({ toggle })),
        ),
      ),
    ),
  );

  onShowAddAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAssessmentComplete),
      map(() => showCustomNotification({ message: 'Assessment created: Please, review the details.' })),
    ),
  );

  onSelectAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAssessment),
      switchMap(({ id }) =>
        this.assessmentsService.get(id).pipe(
          map(assessment =>
            fromActions.selectAssessmentComplete({ assessment: { actions: assessment.actions, ...assessment.assessment } }),
          ),
          catchError(({ error }) => of(fromActions.selectAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onUpdateAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAssessment),
      switchMap(({ id, assessment, isDraft }) =>
        this.assessmentsService.update(id, { ...assessment }).pipe(
          map(assessment =>
            fromActions.updateAssessmentComplete({ assessment: { actions: assessment.actions, ...assessment.assessment }, isDraft }),
          ),
          catchError(({ error }) => of(fromActions.updateAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onUpdateAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAssessmentComplete),
      switchMap(() =>
        this.store.pipe(
          select(fromSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAssessmentsList({ toggle })),
        ),
      ),
    ),
  );

  onSubmitAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitAssessment),
      switchMap(() =>
        this.store.pipe(
          select(assessmentsListSelectors.selectSelectedAssessmentId),
          take(1),
          switchMap(id =>
            this.assessmentsService.submit(id!).pipe(
              map(assessment =>
                fromActions.submitAssessmentComplete({
                  assessment: { actions: assessment.actions, ...assessment.assessment },
                  redirectUrl: `mm-program`,
                }),
              ),
              catchError(({ error }) => of(fromActions.submitAssessmentError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  onSubmitAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitAssessmentComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      switchMap(() =>
        this.store.pipe(
          select(fromSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAssessmentsList({ toggle })),
        ),
      ),
    ),
  );

  onCancelAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelAssessment),
      switchMap(() =>
        this.store.pipe(
          select(assessmentsListSelectors.selectSelectedAssessmentId),
          take(1),
          switchMap(id =>
            this.assessmentsService.cancel(id!).pipe(
              map(assessment =>
                fromActions.cancelAssessmentComplete({
                  assessment: { actions: assessment.actions, ...assessment.assessment },
                  redirectUrl: `mm-program`,
                }),
              ),
              catchError(({ error }) => of(fromActions.cancelAssessmentError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  onCancelAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelAssessmentComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      switchMap(() =>
        this.store.pipe(
          select(fromSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAssessmentsList({ toggle })),
        ),
      ),
    ),
  );

  onDeleteAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAssessment),
      switchMap(() =>
        this.store.pipe(
          select(assessmentsListSelectors.selectSelectedAssessmentId),
          take(1),
          switchMap(id =>
            this.assessmentsService.delete(id!).pipe(
              map(() => fromActions.deleteAssessmentComplete({ redirectUrl: `mm-program` })),
              catchError(({ error }) => of(fromActions.deleteAssessmentError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAssessmentComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => assessmentsListActions.getAssessmentsList({ toggle: AssessmentToggleOptions.Open })),
    ),
  );

  onAddCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomer),
      switchMap(({ customer }) =>
        this.customersService.create({ ...customer }).pipe(
          map((customer: ICustomer) => fromActions.addCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.addCustomerError({ error }))),
        ),
      ),
    ),
  );

  onAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      map(({ customer }) => fromActions.addAssessment({ assessment: { customerId: customer.id } })),
    ),
  );

  onDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadFile),
      switchMap(({ id, fileId }) =>
        this.assessmentsService.getDownloadUrl(id, fileId).pipe(
          map(res => fromActions.downloadFileComplete({ url: res.URL })),
          catchError(({ error }) => of(fromActions.downloadFileError({ error }))),
        ),
      ),
    ),
  );

  onDownloadSelectedFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadFileComplete),
        tap(({ url }) => {
          downloadBlobUrlFileToBrowser(url);
        }),
      ),
    { dispatch: false },
  );

  onSubmitUpdateAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitUpdateAssessment),
      switchMap(({ id, submitUpdate }) =>
        this.assessmentsService.submitUpdate(id, submitUpdate).pipe(
          map(assessment =>
            fromActions.submitUpdateAssessmentComplete({
              assessment: { actions: assessment.actions, ...assessment.assessment },
              redirectUrl: `mm-program`,
            }),
          ),
          catchError(({ error }) => of(fromActions.submitUpdateAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onSubmitUpdateAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitUpdateAssessmentComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => assessmentsListActions.getAssessmentsList({ toggle: AssessmentToggleOptions.Open })),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getAssessmentsListError,
        fromActions.addAssessmentError,
        fromActions.selectAssessmentError,
        fromActions.updateAssessmentError,
        fromActions.submitAssessmentError,
        fromActions.cancelAssessmentError,
        fromActions.deleteAssessmentError,
        fromActions.addCustomerError,
        fromActions.downloadFileError,
        fromActions.submitUpdateAssessmentError,
      ),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  constructor(
    private readonly actions$: Actions,

    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly assessmentsService: AssessmentsService,
    private readonly customersService: CustomersService,
  ) {}
}
