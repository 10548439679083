import { createReducer, on } from '@ngrx/store';
import { NewsArticle, NewsArticleCommentListItem, NewsArticleListItem } from './news-article.model';
import * as fromActions from './news-articles.actions';

export interface State {
  promotedArticlesLoading: boolean;
  filteredArticlesLoading: boolean;

  filteredNewsArticles: NewsArticleListItem[];
  promotedNewsArticles: NewsArticleListItem[];

  openedNewsArticle: NewsArticle | null;
  isOpenedNewsArticleLoading: boolean;

  imagesLoading: boolean;

  sortType: string;

  comments: NewsArticleCommentListItem[];
  createCommentInProgress: boolean;
  commentsLoading: boolean;
  commentLoading: boolean;
  isReactionLoading: boolean;
}

export const initialState: State = {
  promotedArticlesLoading: false,
  filteredArticlesLoading: false,

  filteredNewsArticles: [],
  promotedNewsArticles: [],

  openedNewsArticle: null,
  isOpenedNewsArticleLoading: false,

  imagesLoading: false,

  sortType: 'PublishedDate desc',

  comments: [],
  createCommentInProgress: false,
  commentsLoading: false,
  commentLoading: false,
  isReactionLoading: false,
};

export const newsArticlesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getFilteredNewsArticles, state => ({ ...state, filteredArticlesLoading: true })),
  on(fromActions.getFilteredNewsArticlesComplete, (state, { newsArticles, regionId }) => ({
    ...state,
    filteredArticlesLoading: false,
    filteredNewsArticles: sortItems<NewsArticleListItem>(newsArticles, getSortFunc(state.sortType, regionId)),
  })),
  on(fromActions.getFilteredNewsArticlesError, state => ({ ...state, filteredArticlesLoading: false })),

  on(fromActions.getPromotedNewsArticles, state => ({ ...state, promotedArticlesLoading: true })),
  on(fromActions.getPromotedNewsArticlesComplete, (state, { newsArticles, regionId }) => ({
    ...state,
    promotedArticlesLoading: false,
    promotedNewsArticles: sortItems<NewsArticleListItem>(newsArticles, getSortFunc(state.sortType, regionId)),
  })),
  on(fromActions.getPromotedNewsArticlesError, state => ({ ...state, promotedArticlesLoading: false })),

  on(fromActions.reOrderFilteredNewsArticles, (state, { sortType, regionId }) => ({
    ...state,
    sortType,
    filteredNewsArticles: sortItems<NewsArticleListItem>(state.filteredNewsArticles, getSortFunc(sortType, regionId)),
  })),

  on(fromActions.getOpenedNewsArticle, state => ({ ...state, isOpenedNewsArticleLoading: true })),
  on(fromActions.getOpenedNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isOpenedNewsArticleLoading: false,
    openedNewsArticle: newsArticle,
  })),
  on(fromActions.getOpenedNewsArticleError, state => ({ ...state, isOpenedNewsArticleLoading: false })),

  on(fromActions.getOpenedNewsArticle, state => ({ ...state, isOpenedNewsArticleLoading: true })),

  on(fromActions.getOpenedNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isOpenedNewsArticleLoading: false,
    openedNewsArticle: newsArticle,
  })),
  on(fromActions.getOpenedNewsArticleError, state => ({
    ...state,
    isOpenedNewsArticleLoading: false,
  })),

  on(fromActions.reactOnNewsArticle, state => ({ ...state, isLoading: true, isReactionLoading: true })),
  on(fromActions.reactOnNewsArticleComplete, state => ({
    ...state,
    isLoading: false,
    isReactionLoading: false,
  })),
  on(fromActions.reactOnNewsArticleError, state => ({
    ...state,
    isLoading: false,
    isReactionLoading: false,
  })),

  on(fromActions.getAllNewsArticleComments, state => ({ ...state, commentsLoading: true })),
  on(fromActions.getAllNewsArticleCommentsComplete, (state, { comments }) => ({
    ...state,
    commentsLoading: false,
    comments: sortByDateTimeCommentsRecursively(comments),
  })),
  on(fromActions.getAllNewsArticleCommentsError, state => ({ ...state, commentsLoading: false })),

  on(fromActions.createNewsArticleComment, state => ({
    ...state,
    commentLoading: true,
    commentsLoading: true,
    isLoading: true,
  })),
  on(fromActions.createNewsArticleCommentComplete, (state, { comment }) => ({
    ...state,
    comments: addComment(state.comments, comment),
    commentLoading: false,
    commentsLoading: false,
    isLoading: false,
  })),
  on(fromActions.createNewsArticleCommentError, state => ({
    ...state,
    commentLoading: false,
    commentsLoading: false,
    isLoading: false,
  })),

  on(fromActions.reactOnNewsArticleComment, state => ({ ...state, isLoading: true, isReactionLoading: true })),
  on(fromActions.reactOnNewsArticleCommentComplete, (state, { comment }) => ({
    ...state,
    comments: updateComment(state.comments, comment),
    isLoading: false,
    isReactionLoading: false,
  })),
  on(fromActions.reactOnNewsArticleCommentError, state => ({
    ...state,
    isLoading: false,
    isReactionLoading: false,
  })),
);

export const sortItems = <T>(array: T[], sorting: (a: T, b: T) => number): T[] => {
  return array.slice().sort((a, b) => sorting(a, b));
};

export const getSortFunc = (type: string, regionId: string) => {
  switch (type) {
    case 'PublishedDate desc':
      return latestSortFunc(regionId);
    case 'LikesCount desc':
      return trendingSortFunc;
  }
  return latestSortFunc(regionId);
};

export const latestSortFunc = (regionId: string) => {
  return (a: NewsArticleListItem, b: NewsArticleListItem) =>
    getUsableArticleDate(a, regionId) < getUsableArticleDate(b, regionId) ? 1 : -1;
};

export const getUsableArticleDate = (newsArticle: NewsArticleListItem, regionId: string) => {
  const regionApprovedDate = newsArticle.regions.find(item => item.id === regionId)!.approvedDateTime;
  return regionApprovedDate < newsArticle.validFromDate! ? newsArticle.validFromDate! : regionApprovedDate;
};

export const sortByDateTimeComment = (a: NewsArticleCommentListItem, b: NewsArticleCommentListItem) =>
  a.createdDateTime < b.createdDateTime ? 1 : -1;
export const sortByDateTimeCommentsRecursively = (comments: NewsArticleCommentListItem[]): NewsArticleCommentListItem[] => {
  return comments
    .map(comment => ({
      ...comment,
      comments: sortByDateTimeCommentsRecursively(comment.comments),
    }))
    .sort(sortByDateTimeComment);
};
export const trendingSortFunc = (a: NewsArticleListItem, b: NewsArticleListItem) => (a.likesCount < b.likesCount ? 1 : -1);
export const findAndRemoveComment = (
  comments: NewsArticleCommentListItem[],
  predicate: (comment: NewsArticleCommentListItem) => boolean,
): NewsArticleCommentListItem[] => {
  return comments.reduce((acc, comment) => {
    if (!predicate(comment)) {
      const newComment = { ...comment };

      if (newComment.comments) {
        newComment.comments = findAndRemoveComment(newComment.comments, predicate);
      }

      acc.push(newComment);
    }
    return acc;
  }, [] as NewsArticleCommentListItem[]);
};

export const findAndReplace = <T>(array: T[], newItem: T, predicate: (item: T) => boolean): T[] => {
  const newArray: T[] = [];

  for (const item of array) {
    if (predicate(item)) {
      newArray.push(newItem);
    } else {
      newArray.push(item);
    }
  }

  return newArray;
};

export const addComment = (
  comments: NewsArticleCommentListItem[],
  newComment: NewsArticleCommentListItem,
): NewsArticleCommentListItem[] => {
  if (!newComment.parentCommentId) {
    return sortItems([...comments, newComment], sortByDateTimeComment);
  }

  return comments.map(comment => {
    if (comment.id === newComment.parentCommentId) {
      const updatedChildren = sortItems([...(comment.comments || []), newComment], sortByDateTimeComment);
      return {
        ...comment,
        comments: updatedChildren,
      };
    } else if (comment.comments && comment.comments.length > 0) {
      return {
        ...comment,
        comments: addComment(comment.comments, newComment),
      };
    } else {
      return comment;
    }
  });
};

export const updateComment = (
  comments: NewsArticleCommentListItem[],
  updatedComment: NewsArticleCommentListItem,
): NewsArticleCommentListItem[] => {
  return comments.map(comment => {
    if (comment.id === updatedComment.id) {
      return {
        ...comment,
        ...updatedComment,
        userName: comment.userName,
        comments: comment.comments,
      };
    } else if (comment.comments && comment.comments.length > 0) {
      return {
        ...comment,
        comments: updateComment(comment.comments, updatedComment),
      };
    } else {
      return comment;
    }
  });
};
