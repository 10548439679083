import { CustomPathsActivator } from '@1clickfactory/common/helpers/custom-paths-activator';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgreementsGuard, AuthGuard, PermissionsGuard, PublicAgreementsGuard } from '@appState';
import { GuidGuard } from './app-state/identity/guards/guid.guard';
import { AfterSignInComponent } from './components/after-sign-in/after-sign-in.component';
import { AuthenticatedTemplateComponent } from './components/authenticated-template/authenticated-template.component';
import { DashboardResolver } from './components/dashboard/dasboard.resolver';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NewsArticleViewResolver } from './news-articles/pages/news-article-view/news-article-view.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'after-sign-in' },
  { path: 'after-sign-in', component: AfterSignInComponent, canActivate: [AuthGuard] },
  {
    path: 'anonymous',
    loadChildren: () => import('./components/anonymous/anonymous.module').then(m => m.AnonymousModule),
  },
  {
    path: 'agreements/:publicLink',
    loadComponent: () => import('./components/agreement-view/agreement-view.component').then(m => m.AgreementViewComponent),
    canActivate: [PublicAgreementsGuard],
  },
  {
    path: 'settings/agreements-signed/:agreementId/version/:versionId',
    loadComponent: () =>
      import('./settings/pages/agreements-signed/agreement-signed-view.component').then(m => m.AgreementSignedViewComponent),
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permissions: ['Portal.Business.Agreements.Signatures.Read'] },
  },
  {
    path: '',
    component: AuthenticatedTemplateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: { data: DashboardResolver },
      },
      {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.ShoppingCart.MenuAccess'], pageTitle: 'Shopping Cart' },
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.Documents.MenuAccess'], pageTitle: 'Documents' },
      },
      {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.Support.MenuAccess'], pageTitle: 'Support' },
      },
      {
        path: 'page',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule),
      },
      {
        path: 'project',
        loadChildren: () => import('./project-module/project.module').then(m => m.ProjectModule),
        canActivate: [AgreementsGuard, CustomPathsActivator, PermissionsGuard],
        data: { permissions: ['Portal.Business.Projects.MenuAccess'], pageTitle: 'Projects' },
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.Settings.Users.ActionAccess'], pageTitle: 'Users', returnUrl: 'settings' },
      },
      {
        path: 'impersonation',
        loadComponent: () => import('./impersonation/impersonation.component').then(m => m.ImpersonationComponent),
        canActivate: [AgreementsGuard],
        data: { permissions: ['Portal.Business.Impersonation.MenuAccess'], pageTitle: 'Partners' },
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.Settings.MenuAccess'], pageTitle: 'Settings' },
      },
      {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.Customers.Read'], pageTitle: 'Customers' },
      },
      {
        path: 'mm-program',
        loadChildren: () => import('./mm-program/mm-program.module').then(m => m.MMProgramModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.MM.MenuAccess'], pageTitle: 'MM Program' },
      },
      {
        path: 'news-articles',
        loadChildren: () => import('./news-articles/news-articles.module').then(m => m.NewsArticlesModule),
        canActivate: [PermissionsGuard],
        data: { permissions: ['Portal.Business.NewsArticles.MenuAccess'], pageTitle: 'News' },
      },
      { path: '**', redirectTo: 'page/page-not-found' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [
    AgreementsGuard,
    AuthGuard,
    CustomPathsActivator,
    PermissionsGuard,
    DashboardResolver,
    PublicAgreementsGuard,
    NewsArticleViewResolver,
    GuidGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
