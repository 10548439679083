export const currentAddressCookieKey = 'current_address';

export function manageCurrentAddressSessionStorage(url: string | undefined): void {
  if (url === undefined || url.includes('settings/agreements-signed/') || url.includes('after-sign-in')) {
    sessionStorage.removeItem(currentAddressCookieKey);
    return;
  }
  const exceptional_uris = ['/after-sign-in', 'settings/agreements-signed/', '/page/page-not-found'];

  if (!exceptional_uris.includes(url)) {
    sessionStorage.setItem(currentAddressCookieKey, url);
  }
}

export class Cookies {
  static getAll(): string {
    return document.cookie;
  }

  static getCookie(name: string): string | null {
    const cookies: string = Cookies.getAll();
    const cookieStartIndex: number = cookies.indexOf(name);

    if (cookieStartIndex === -1) {
      return null;
    }

    const cookieEndIndex: number = cookies.indexOf(';', cookieStartIndex);

    return cookieEndIndex !== -1 ? cookies.substr(cookieStartIndex, cookieEndIndex - cookieStartIndex) : cookies.substr(cookieStartIndex);
  }

  static get(name: string): string | null {
    const cookie = Cookies.getCookie(name);

    if (cookie === null) {
      return null;
    }

    const valueStartIndex: number = cookie.indexOf('=') + 1;
    return cookie.substr(valueStartIndex);
  }

  static set(name: string, value: string): void {
    const cookieExpiration: Date = new Date();
    cookieExpiration.setTime(cookieExpiration.getTime() + 30 * 24 * 60 * 60 * 1000);

    const cookieDomain = '/';

    document.cookie = `${name}=${value}; path=${cookieDomain}`;
  }

  static remove(name: string): void {
    const cookieExpiration: Date = new Date(2000, 1, 1, 1, 1, 1, 1);

    document.cookie = `${name}=;expires=${cookieExpiration}; path=/`;
  }
}
